import React from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Text } from '../UIKit/system';
import Link from '../link';
import { getPath } from '../../utils/paths';
import { getThemeColor } from '../../utils/styles';
import mq from '../../utils/mq';
import ChevronRight from '../../images/svg/chevron-right.svg';

const pseudoFill = (props) => css`
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  transform: translateX(-100%);
  background-color: ${getThemeColor('black')(props)};
`;

const StyledTopBar = styled(Box)`
  position: relative;

  &:before {
    ${pseudoFill};
  }

  ${mq.tablet(css`
    &:after {
      ${pseudoFill};
      left: auto;
      right: 0;
      transform: translateX(100%);
    }
  `)};
`;

const StyledLink = styled(Link)`
  position: absolute;
  left: 0;

  ${mq.mobile(css`
    font-size: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
    }
  `)};
`;

const StyledChevronRight = styled(ChevronRight)`
  fill: #959595;
  width: 14px;
  height: 11px;
  display: none;
  transform: scaleX(-1);

  ${mq.mobile(css`
    display: block;
  `)};
`;

const StyledTitle = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 620px;
  white-space: nowrap;

  ${mq.tablet(css`
    max-width: 400px;
  `)};

  ${mq.mobile(css`
    max-width: 280px;
  `)};

  ${mq.mobileSmall(css`
    max-width: 220px;
  `)};
`;

function TopBar({ title }) {
  return (
    <StyledTopBar bg={'black'} height={52}>
      <Flex
        position={'relative'}
        height={'100%'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <StyledLink
          to={getPath.downloads({ category: 'cad-models' })}
          color={'#959595'}
          fontSize={14}
          hover
        >
          All CAD Models
          <StyledChevronRight />
        </StyledLink>
        <StyledTitle fontSize={[16, 18]} color={'#fff'} bold>
          {title}
        </StyledTitle>
      </Flex>
    </StyledTopBar>
  );
}

export default TopBar;
