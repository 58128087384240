import React from 'react';

import PageStatic from '../components/page-static';
import CadViewer from '../components/cad-viewer';

function CadViewerPage({ pageContext, ...rest }) {
  const { story } = pageContext;
  const content = JSON.parse(story.content);
  return (
    <PageStatic title={'CAD Viewer'} {...rest}>
      <CadViewer {...content} />
    </PageStatic>
  );
}

export default CadViewerPage;
