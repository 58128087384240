import React from 'react';
import styled from 'styled-components';

import { Flex, Text } from '../UIKit/system';
import Link from '../link';
import ArrowInCircle from '../arrow-in-circle';

const StyledFileLink = styled(Flex)``;

const Separator = styled.div`
  height: 32px;
  width: 1px;
  background-color: #d4d4d4;
`;

const StyledArrowInCircle = styled(ArrowInCircle)`
  margin: 0 13px;
`;

function FileLink({ children, ...rest }) {
  return (
    <StyledFileLink
      as={Link}
      alignItems={'center'}
      py={10}
      pl={17}
      min-height={64}
      bg={'#f8f8f8'}
      width={'100%'}
      maxWidth={'100%'}
      hover
      justifyContent={'space-between'}
      {...rest}
    >
      <Text fontSize={14} bold pr={10}>
        {children}
      </Text>
      <Flex alignItems={'center'}>
        <Separator />
        <StyledArrowInCircle down />
      </Flex>
    </StyledFileLink>
  );
}

export default FileLink;
