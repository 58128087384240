import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Text } from '../UIKit/system';
import Sidebar from './sidebar';
import TopBar from './top-bar';
import withLocation from '../with-location';
import Container from '../container';
import { CONTAINER_WIDTH_LARGE } from '../../constants';
import mq from '../../utils/mq';
import Spinner from '../spinner';

const StyledCadViewer = styled.div``;

const IframeContainer = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  height: calc(100vh - 189px);
  min-height: 650px;

  ${mq.mobile(css`
    height: 450px;
  `)};
`;

const IframeInnerContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  ${mq.mobile(css`
    width: 100%;
  `)};
`;

const StyledIframe = styled.iframe`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: calc(100% + 40px);
  border: none;
  -webkit-appearance: none;
  margin-left: -40px;
  ${mq.mobile(css`
    position: static;
    width: 100%;
  `)};
`;

const SpinnerContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function CadViewer({ name, file, cadFilesToDownload, autoDeskID, ...rest }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  function handleIframeLoad() {
    setLoading(false);
  }

  return (
    <StyledCadViewer>
      <Container
        as={Flex}
        large
        flexDirection={['column', null, 'row']}
        style={{ maxWidth: '100%', minWidth: '400px' }}
      >
        <Box flex={1}>
          <TopBar title={name} />
          <IframeContainer>
            <IframeInnerContainer>
              {autoDeskID && (
                <StyledIframe
                  src={`https://trustsertus.autodesk360.com/shares/public/${autoDeskID}?mode=embed`}
                  scrolling="no"
                  onLoad={handleIframeLoad}
                  height={'100%'}
                  width={'100%'}
                />
              )}
              {loading && (
                <SpinnerContainer>
                  <Flex flexDirection={'column'} alignItems={'center'}>
                    <Spinner />
                    {/*<Text*/}
                    {/*  maxWidth={300}*/}
                    {/*  textAlign={'center'}*/}
                    {/*  mt={15}*/}
                    {/*  fontSizeSmall*/}
                    {/*  color={'grey'}*/}
                    {/*>*/}
                    {/*  Please wait, it may take some time to&nbsp;process a CAD*/}
                    {/*  file*/}
                    {/*</Text>*/}
                  </Flex>
                </SpinnerContainer>
              )}
            </IframeInnerContainer>
          </IframeContainer>
        </Box>
        <Sidebar filesToDownload={cadFilesToDownload} />
      </Container>
    </StyledCadViewer>
  );
}

CadViewer = withLocation(CadViewer);

export default CadViewer;
