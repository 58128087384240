import React from 'react';
import styled, { css } from 'styled-components';

import FileLink from './file-link';
import { Text } from '../UIKit/system';
import mq from '../../utils/mq';

const StyledSidebar = styled.div`
  max-width: 458px;
  min-width: 300px;
  width: 20%;
  padding: 18px 0 18px 23px;

  ${mq.tablet(css`
    max-width: none;
    width: auto;
    min-width: 0px;
    margin-top: 60px;
    padding: 0 0 40px;
  `)};
`;

const Links = styled.div`
  display: grid;
  grid-gap: 8px;
`;

function Sidebar({ filesToDownload }) {
  return (
    <StyledSidebar>
      <Text mb={32} as={'h2'} bold>
        Downloads
      </Text>
      {filesToDownload && filesToDownload.length > 0 ? (
        <Links>
          {filesToDownload.map((item, index) => {
            return (
              <FileLink
                key={index}
                to={item.file && item.file.filename}
                download
              >
                {item.name}
              </FileLink>
            );
          })}
        </Links>
      ) : (
        <Text>No files to download</Text>
      )}
    </StyledSidebar>
  );
}

export default Sidebar;
